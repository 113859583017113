.divPadre {
    position: fixed;
    top: 0;
    width: 0px;
    height: 0px;
    background-color:rgba(255,255,255, 0.8);
    display: flex;
    font-size: 20px;
    overflow: hidden;
}

.divHijo {
    text-align: center;
    margin: auto;
}

.spinerReactstrap {
    width: 20px;
    height: 20px;
    border: 10px solid currentColor;
    border-right-color: aqua;
}

.activo{
    height: 100%;
    width: 100%;
    transition: all 0.5s ease-in;
}

.btn-download{
    fill: #233e58;
    cursor: pointer;
}

.btn-download:hover svg{
    border-radius: 5px;
    border: 1px solid #233e58;    
}

.link-encuesta{
    text-decoration: none;
    color: #233e58;
}

.link-encuesta:hover {
    text-decoration: none; /* Quita el subrayado al pasar el ratón */
    color: #233e58;
  }