@font-face {
  font-family: "Metropolis";
  src: url("../../assets/fonts/Metropolis-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-bold";
  src: url("../../assets/fonts/metropolis.bold.otf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.text-center {
  text-align: center !important;
}

.container {
  background: #fef9e5 0% 0% no-repeat padding-box;
  border: 2px solid #fce8a2;
  border-radius: 1rem;
  opacity: 1;
  margin: 1rem auto !important; /* Margen de 1 rem alrededor */
  max-width: calc(
    100% - 2rem
  ) !important; /* Restamos 2rem para que el margen de 1rem quede a ambos lados */
}

.input-document {
  background: #fefcf2 0% 0% no-repeat padding-box;
  border: 1px solid #fce8a2;
  border-radius: 16px;
  opacity: 1;
}

/* Centrar el reCAPTCHA y el botón */
.ReCAPTCHA {
  display: flex;
  justify-content: center;
  margin-bottom: 16px; /* Ajusta según el espacio que desees */
}

.button-container {
  display: flex;
  justify-content: center;
}

.button-container button {
  width: 166px;
  height: 40px;
  /* UI Properties */
  background: var(--main-c2383a) 0% 0% no-repeat padding-box;
  background: rgb(200 32 48);
  border-radius: 100px;
  opacity: 1;
  padding-top: 0.688rem;
  padding-bottom: 0.688rem;
  line-height: 1rem;
}

.button-container button:hover {
  background-color: rgb(2 61 89);
  border-color: rgb(2 61 89);
}

.font-metropolis {
  font-family: "Metropolis-bold" !important;
}

.font-roboto {
  font-family: "Roboto" !important;
  font-size: 20px;
}

@media (max-width: 768px) {
  .font-roboto {
    font-size: 18px !important;
  }
}


.info-title {
  font-size: 24px;
}

.input-document .font-metropolis {
  font-size: 30px;
}

.font-metropolis.tittle {
  font-size: 48px;
  font-weight: bold;
  border-bottom: 10px solid #f4c107;
  display: inline-block;
}

@media (max-width: 360px) {
  .font-metropolis.tittle {
    font-size: 30px;
  } 
}

.font-metropolis.text {
  font-size: 20px;
}

.font-metropolis button {
  font-size: 18px !important;
  font-weight: bold;
}

.input-document p {
  font-family: "Roboto" !important;
  font-size: 20px;
}

.icon-container {
  display: inline-flex;
  background-color: #f4c1074d; /* Color dorado con transparencia */
  border-radius: 50%; /* Hace que el fondo sea redondo */
  padding: 10px; /* Espacio interno para que el icono no esté pegado al borde */
}
