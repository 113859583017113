@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* background: #f2f2f2; */
  background-size: contain;
  font-family: "Roboto", sans-serif;
}

.login-box {
  /*  width: 320px;
    height: 480px;*/
  background: #013d57;
  color: #fff;
  /*top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    box-sizing: border-box;*/
  padding: 30px 30px 10px 30px;
  border-radius: 50px;
  margin: 20px 30px;
}

.login-box label {
  margin: 0;
  padding: 0;
  font-weight: bold;
  display: block;
  text-align: center;
  margin: 20px 0;
}

.login-box h1 {
  background-color: black;
  margin: 0;
  padding: 0 0 20px;
  text-align: center;
  font-size: 22px;
}

.login-box .avatar {
  /*width: 120px;*/
  height: 50px;
  /*position: absolute;*/
  top: 1em;
  left: calc(46% - 50px);
  margin-bottom: 40px;
}

.formulario {
  /*  width: 320px;
        height: 480px;
        background:#013d57;
        color: #fff;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        padding: 70px 30px;*/
}

.formulario input {
  width: 100%;
  margin: 10px;
  max-width: 300px;
  border-radius: 10px;
  text-align: center;
}

.formulario input:focus {
  border: 1px solid #4d77ff;
}

.formulario .recaptcha {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 10px;
}

.formulario .recaptcha>div {
	border-radius: 20px !important;
	overflow: hidden;
}

.formulario button {
  border: none;
  height: 40px;
  width: 100px;
  background: #86a83d;
  color: #fff;
  font-size: 18px;
  border-radius: 100px;
  display: inline-block;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.formulario button:hover {
  background: #1f45c4;
}

.error-captcha {
  color: red;
  margin-bottom: 20px;
}

.contenedor {
 /* display: flex; /* Usar el modelo de caja flexible */
 /* justify-content: center; /* Centrar horizontalmente */
  /*align-items: center; /* Centrar verticalmente */
  max-width: 38rem;
  margin: auto;
  text-align: justify;
}

.centrar {
}

.info {
  padding: 25px 20%;
  width: 100%;
  background-color: #d2dee0;
  text-align: justify;
}


.info a{
  color: inherit;
    text-decoration: none; 
}